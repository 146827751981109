import React, { useEffect, useState } from "react";
import MainDetails from "./main-details";
import Input from "../../components/preview-input/input";
import Drawing from "./drawing";
import MeasurementsLeft from "./measurements-left";
import MeasurementsRight from "./measurements-right";
import Fascia from "./fascia";
import Soffit from "./soffit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import Success from "../../components/success/success";
import usePreviewStore from "../../store/preview-store";
import axiosInstance, { endpoints } from "../../utils/axios";
import axios from "axios";
import { HOST_API, SALES_FORCE_LOGIN_URL } from "../../config-global";
import { toast } from "react-toastify";
import { convertErrorsToArray } from "../../utils/formats";
import useResultStore from "../../store/result-store";
import { Loader } from "@progress/kendo-react-indicators";
import qs from "querystring";
import { getDataForSalesFor } from "./utilts";
import TableComponent from "./table-component";
const Preview = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const { previewData, setFormData, reset } = usePreviewStore();
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadId = queryParams.get("leadId");
  const [pricesValues, setPricesValue] = useState();
  const { resultData } = useResultStore();
  console.log(previewData, "here we go preview Data");
  useEffect(() => {
    getSheet();
  }, []);
  const salesForceLogin = async (payload) => {
    try {
      const response = await axiosInstance.get(endpoints.salesForce.login);
      await salesForceGutterSheet(response.data.access_token, payload);
    } catch (error) {
      console.error(
        "Error Logging into Salesforce:",
        error.response?.data || error.message
      );
    }
  };
  function startsWithError(message) {
    return message.startsWith("Error");
  }
  const salesForceGutterSheet = async (token, data) => {
    try {
      const response = await axios.post(
        `https://abc742-dev-ed.develop.my.salesforce.com/services/apexrest/api/GuttersAPI`,
        JSON.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (startsWithError(response.data)) {
        toast.error(`Error in SF Api : ${response.data}`);
      }
      console.log("Salesforce Login Success:", response.data);
    } catch (error) {
      console.error(
        "Error Logging into Salesforce:",
        error.response?.data || error.message
      );
    }
  };

  const getSheet = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.leads.get_sheet_detail(leadId)
      );
      if (response.data) {
        console.log(response.data.data);
        let data = response.data.data;
        if (data.customer) {
          console.log(true, "here we go preview Data");
          const values = {
            customer: data.customer || "",
            city: data.city || "",
            st: data.street || "",
            measurements: data.measurements,
            roof_type: data.roof.roofType || "",
            ribbedRoofQuantity: data.ribbedRoofQuantity || 0,
            gutter_type: data.roof.gutterType || "",
            color: data.roof.gutterColor || "",
            gutter_size: data.roof.gutterSize || "",
            total_gutter_footage: data.roof.totalGutterFootage || "",
            downspout: data.roof.downSpout || "",
            downspout_color: data.roof.downSpoutColor || "",
            aelbows: data.roof.downSpoutFootage.ofAElbows || "",
            belbows: data.roof.downSpoutFootage.ofBElbows || "",
            roundElbows: data.roof.downSpoutFootage.ofRoundElbows || "",
            offsetElbows: data.roof.downSpoutFootage.ofOffsetElbows || "",
            downspout_footage: data.roof.downSpoutFootage.totalFootage || "",
            outside: data?.misc?.ofMitersOutside || "",
            inside: data?.misc?.ofMitersInside || "",
            rights: data?.misc?.ofEndcapsRights || "",
            lefts: data?.misc?.ofEndcapsLefts || "",
            savers: data?.misc?.ofSproutSavers || "",
            straps: data?.misc?.ofRoofStraps || "",
            wedges: data?.misc?.ofwedges || "",
            partial_install: data?.misc?.partialInstall ? "Yes" : "No",
            fascia_replacement: data?.fasciaReplacement?.type || "",
            fascia_total_footage: data?.fasciaReplacement?.totalFootage || "",
            fascia_size:
              data?.fasciaReplacement?.size != "1x4" &&
              data?.fasciaReplacement?.size != "1x6" &&
              data?.fasciaReplacement?.size != "1x8" &&
              data?.fasciaReplacement?.size != "1x10"
                ? "Other"
                : data?.fasciaReplacement?.size,
            fascia_other_size: data?.fasciaReplacement?.size,
            fascia_type: data?.fasciaReplacement?.productType || "",
            soffit_other_value:
              data?.fasciaReplacement?.productType != "Painted" &&
              data?.fasciaReplacement?.productType != "Metal Wrapped "
                ? data?.fasciaReplacement?.productType
                : "",
            fascia_other:
              data?.fasciaReplacement?.productDetail != "Painted" &&
              data?.fasciaReplacement?.productDetail != "Metal Wrapped"
                ? "Other"
                : data?.fasciaReplacement?.productDetail,
            fascia_other_value:
              data?.fasciaReplacement?.productDetail != "Painted" &&
              data?.fasciaReplacement?.productDetail != "Metal Wrapped"
                ? data?.fasciaReplacement?.productDetail
                : "",
            metal_wrapped_color: data?.metalWrappedColor || "WHITE",
            fascia_wrap: data?.fasciaReplacement?.totalFtOfNewWrap || "",
            soffit_replacement: data?.soffitReplacement?.replacementType || "",
            soffit_other:
              data?.soffitReplacement?.soffitType != "Wood" &&
              data?.soffitReplacement?.soffitType != "Vinyl"
                ? "Other"
                : data?.soffitReplacement?.soffitType,
            soffit_other_value:
              data?.soffitReplacement?.soffitType != "Wood" &&
              data?.soffitReplacement?.soffitType != "Vinyl"
                ? data?.soffitReplacement?.soffitType
                : "",
            soffit_color: data?.soffitReplacement?.soffitColor || "",
            soffit_total_footage: data?.soffitReplacement?.totalFootage || "",
            porch_ceiling: data?.porchCeiling,
            images: data.images,
          };
          setFormData(values);
          setShowButton(false);
          setLoading(false);
        } else {
          setShowButton(true);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };
  const onSubmit = async () => {
    setLoading(true);
    const subdata = {
      customer: previewData.customer || "",
      city: previewData.city || "",
      street: previewData.st || "",
      leadId: leadId,
      // leadId: Math.random().toString(),
      measurements: previewData.measurements,
      roof: {
        sumOfAllFootage:
          Number(previewData?.total_gutter_footage) +
          Number(previewData?.aelbows) +
          Number(previewData?.belbows) +
          Number(previewData?.roundElbows) +
          Number(previewData?.offsetElbows) +
          Number(previewData.downspout_footage),
        roofType: previewData.roof_type || "",
        ribbedRoofQuantity:
          previewData.roof_type == "Ribbed Roof"
            ? previewData.ribbedRoofQuantity
            : 0,
        gutterType: previewData.gutter_type || "",
        gutterColor: previewData.color || "",
        gutterSize: previewData.gutter_size || "",
        totalGutterFootage: previewData.total_gutter_footage || 0,
        downSpout: previewData.downspout || "",
        downSpoutColor: previewData.downspout_color || "",

        downSpoutFootage: {
          ofAElbows: previewData.aelbows || "",
          ofBElbows: previewData.belbows || "",
          ofRoundElbows: previewData.roundElbows || "",
          ofOffsetElbows: previewData.offsetElbows || "",
          totalFootage: previewData.gutter_downspout_elbows || 0,
        },
      },

      misc: {
        ofMitersOutside: previewData.outside || "",
        ofMitersInside: previewData.inside || "",
        ofEndcapsRights: previewData.rights || "",
        ofEndcapsLefts: previewData.lefts || "",
        ofSproutSavers: previewData.savers || "",
        ofRoofStraps: previewData.straps || "",
        ofwedges: previewData.wedges || "",
        partialInstall: previewData.partial_install == "Yes" ? true : false,
      },

      fasciaReplacement: {
        type: previewData.fascia_replacement || "",
        totalFootage: previewData.fascia_total_footage || 0,
        size:
          previewData.fascia_size === "Other"
            ? previewData.fascia_other_size || ""
            : previewData.fascia_size || "",
        productType:
          previewData.fascia_type == "Other"
            ? previewData.fascia_other_value
            : previewData.fascia_type,
        productDetail:
          previewData.fascia_other == "Other"
            ? previewData.fascia_other_value
            : previewData.fascia_other,
        totalFtOfNewWrap: previewData.fascia_wrap || "",
      },

      soffitReplacement: {
        replacementType: previewData.soffit_replacement || "",
        soffitType:
          previewData.soffit_other == "Other"
            ? previewData.soffit_other_value
            : previewData.soffit_other,
        soffitColor: previewData.soffit_color || "",
        totalFootage: previewData.soffit_total_footage || 0,
      },
      porchCeiling: previewData?.porch_ceiling,
      metalWrappedColor:
        previewData.fascia_other == "Metal Wrapped"
          ? previewData.metal_wrapped_color
          : "",
    };

    const dataForSalesForce = getDataForSalesFor(previewData, subdata.leadId);
    dataForSalesForce.measurements = dataForSalesForce.measurements.map(
      (story, index, measurements) => {
        const updatedStory = Object.fromEntries(
          Object.entries(story).map(([key, value]) => [
            key,
            value === "" ? 0 : value,
          ])
        );

        return updatedStory;
      }
    );
    setLoading(false);
    setPricesValue(dataForSalesForce);

    dataForSalesForce.porchCeiling = dataForSalesForce.porchCeiling.map(
      (item) => ({
        ...item,
        squareFeet: item.squareFeet * 15,
      })
    );

    // dataForSalesForce.misc.ofMitersOutside =
    //   dataForSalesForce.misc.ofMitersOutside * 25;
    // dataForSalesForce.misc.ofMitersInside =
    //   dataForSalesForce.misc.ofMitersInside * 25;

    dataForSalesForce.porchCeiling = dataForSalesForce.porchCeiling.map(
      (item) => ({
        ...item,
        length: item.length === "" ? 0 : item.length,
        width: item.width === "" ? 0 : item.width,
      })
    );
    console.log(dataForSalesForce, "data for sales force api");
    const formData = new FormData();

    // Flattening and appending each key-value pair
    formData.append("customer", subdata.customer);
    formData.append("city", subdata.city);
    formData.append("street", subdata.street);
    formData.append("leadId", subdata.leadId);

    // Roof data
    formData.append("ribbedRoofQuantity", subdata.roof.ribbedRoofQuantity);
    formData.append("roof.roofType", subdata.roof.roofType);
    formData.append("roof.gutterType", subdata.roof.gutterType);
    formData.append("roof.gutterColor", subdata.roof.gutterColor);
    formData.append("roof.gutterSize", subdata.roof.gutterSize);
    formData.append("roof.totalGutterFootage", subdata.roof.totalGutterFootage);
    formData.append("roof.downSpout", subdata.roof.downSpout);
    formData.append("roof.downSpoutColor", subdata.roof.downSpoutColor);

    formData.append(
      "roof.downSpoutFootage.ofAElbows",
      subdata.roof.downSpoutFootage.ofAElbows
    );
    formData.append(
      "roof.downSpoutFootage.ofBElbows",
      subdata.roof.downSpoutFootage.ofBElbows
    );
    formData.append(
      "roof.downSpoutFootage.ofRoundElbows",
      subdata.roof.downSpoutFootage.ofRoundElbows
    );
    formData.append(
      "roof.downSpoutFootage.ofOffsetElbows",
      subdata.roof.downSpoutFootage.ofOffsetElbows
    );
    formData.append(
      "roof.downSpoutFootage.totalFootage",
      subdata.roof.downSpoutFootage.totalFootage
    );
    formData.append("roof.sumOfAllFootage", subdata.roof.sumOfAllFootage);

    // Misc data
    formData.append("misc.ofMitersOutside", subdata.misc.ofMitersOutside);
    formData.append("misc.ofMitersInside", subdata.misc.ofMitersInside);
    formData.append("misc.ofEndcapsRights", subdata.misc.ofEndcapsRights);
    formData.append("misc.ofEndcapsLefts", subdata.misc.ofEndcapsLefts);
    formData.append("misc.ofSproutSavers", subdata.misc.ofSproutSavers);
    formData.append("misc.ofRoofStraps", subdata.misc.ofRoofStraps);
    formData.append("misc.ofwedges", subdata.misc.ofwedges);
    formData.append("misc.partialInstall", subdata.misc.partialInstall);
    formData.append(
      "metalWrappedColor",
      previewData.fascia_other == "Metal Wrapped"
        ? previewData.metal_wrapped_color
        : ""
    );
    // Fascia Replacement
    formData.append("fasciaReplacement.type", subdata.fasciaReplacement.type);
    Object.entries(resultData).forEach(([key, value]) => {
      formData.append(`results[${key}]`, value);
    });
    formData.append(
      "fasciaReplacement.totalFootage",
      subdata.fasciaReplacement.totalFootage
    );
    formData.append("fasciaReplacement.size", subdata.fasciaReplacement.size);
    formData.append(
      "fasciaReplacement.productType",
      subdata.fasciaReplacement.productType
    );
    formData.append(
      "fasciaReplacement.productDetail",
      subdata.fasciaReplacement.productDetail
    );
    formData.append(
      "fasciaReplacement.totalFtOfNewWrap",
      subdata.fasciaReplacement.totalFtOfNewWrap
    );
    // Soffit Replacement
    formData.append(
      "soffitReplacement.replacementType",
      subdata.soffitReplacement.replacementType
    );
    formData.append(
      "soffitReplacement.soffitType",
      subdata.soffitReplacement.soffitType
    );
    formData.append(
      "soffitReplacement.soffitColor",
      subdata.soffitReplacement.soffitColor
    );
    formData.append(
      "soffitReplacement.totalFootage",
      subdata.soffitReplacement.totalFootage
    );

    if (Array.isArray(subdata.porchCeiling)) {
      subdata.porchCeiling.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`porchCeiling[${index}][${key}]`, item[key]);
        });
      });
    }

    if (Array.isArray(subdata.measurements)) {
      subdata.measurements.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`measurements[${index}][${key}]`, item[key]);
        });
      });
    }

    if (previewData.files && Array.isArray(previewData.files)) {
      previewData.files.forEach((file, index) => {
        console.log("file=====>", file);

        formData.append(`files`, file);
      });
    }
    // try {
    //   const response = await axios.post(
    //     `${HOST_API}${endpoints.leads.post_lead_preview}`,
    //     formData,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );
    //   await salesForceLogin(dataForSalesForce);
    //   setLoading(false);
    //   console.log("🚀 ~ onSubmit ~ response:", response.data);
    //   setShowSuccess(true);
    //   setTimeout(() => {
    //     setShowSuccess(false);
    //     navigate(`/`);
    //   }, 2000);
    // } catch (error) {
    //   setLoading(false);
    //   console.error("Error submitting data:", error?.response?.data?.data);
    //   const errorsArray = convertErrorsToArray(error?.response?.data?.data);

    //   if (errorsArray.length > 0) {
    //     errorsArray.forEach((err) => toast.error(err));
    //   } else if (error.message) {
    //     toast.error(error.message);
    //   } else {
    //     toast.error("Internal Server Error");
    //   }
    // }
  };

  return (
    <div className="">
      <h2 className="k-py-4 k-font-bold">Preview</h2>
      <div className="k-rounded-lg k-p-4" style={{ background: "#FFFFFF" }}>
        <div
          className=" k-px-8 k-sm-px-2 k-w-sm--100 k-border-solid k-border k-border-black"
          style={{ width: "60%" }}
        >
          <h2 className="k-text-right k-m-0 k-py-3 k-responsive-text">
            Gutter / Fascia / Soffit Prep Form
          </h2>
        </div>
        <div className="k-border-solid k-border k-border-black k-mt-1">
          <MainDetails id={id} />
        </div>
        <div className=" k-px-4 k-border-solid k-border k-border-black k-mt-1">
          <h2 className="k-m-0 k-py-3">
            Measurements (list each run separate and round up to the nearest
            foot)
          </h2>
        </div>

        <div className="k-px-2 k-border-solid k-border k-border-black k-mt-1 k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
          <MeasurementsLeft />

          <MeasurementsRight />
        </div>

        <Fascia />

        <Soffit />
      </div>
      {showButton ? (
        <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
          <Button
            onClick={() => navigate(`/leads/${id}`)}
            primary={true}
            className="k-button k-mr-2"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            primary={true}
            className="k-button-green k-mr-2"
            size="large"
          >
            {loading ? (
              <Loader
                size="small"
                type="converging-spinner"
                style={{ color: "white" }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      ) : (
        <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
          <Button
            onClick={() => navigate(`/leads/${id}`)}
            primary={true}
            className="k-button k-mr-2"
            size="large"
          >
            Go Back
          </Button>
        </div>
      )}
      {showSuccess && (
        <Success
          visible={showSuccess}
          toggleDialog={() => setShowSuccess(false)}
        />
      )}

      <div className="k-mt-2">
        <h1>Price Calculation (Cost Price)</h1>
        <div style={{ width: "100%", border: "3px solid #8ABD5A" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Lable</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Calculated Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Multiplyed By</b>
            </div>
          </div>
          <TableComponent
            label1={"1st Story Total"}
            label2={pricesValues?.costPrice?.firstStoryTotal}
            label3={
              pricesValues?.costPrice?.inCLudeInPrimaryColors ? 10.5 : 11.5
            }
          />
          <TableComponent
            label1={"2nd  Story Total"}
            label2={pricesValues?.costPrice?.secondStoryTotal}
            label3={
              pricesValues?.costPrice?.inCLudeInPrimaryColors ? 10.5 : 11.5
            }
          />
          <TableComponent
            label1={"3rd  Story Total"}
            label2={pricesValues?.costPrice?.thirldSotryTotal}
            label3={
              pricesValues?.costPrice?.inCLudeInPrimaryColors ? 10.5 : 11.5
            }
          />
          <TableComponent
            label1={"New Meter Box Insdie"}
            label2={0}
            label3={0}
          />
          <TableComponent
            label1={"New Meter Box OutSide"}
            label2={0}
            label3={0}
          />
          {/* <TableComponent label1={'Gutter Sizes'} label2={pricesValues?.costPrice?.gutterSize} label3={14.5}/> */}
          <TableComponent
            label1={"Total Gutter Footage"}
            label2={pricesValues?.costPrice?.totalGutterFootage}
            label3={
              Number(previewData.gutter_size) == 7
                ? 14.5
                : pricesValues?.costPrice?.inCLudeInPrimaryColors
                ? 10.5
                : 11.5
            }
          />
          <TableComponent
            label1={"Downspout Footage"}
            label2={pricesValues?.costPrice?.downspoutFootage}
            label3={
              pricesValues?.costPrice?.inCLudeInPrimaryColors ? 10.5 : 11.5
            }
          />
          <TableComponent
            label1={"# of A elbows"}
            label2={pricesValues?.costPrice?.aElbows}
            label3={
              pricesValues?.costPrice?.inCLudeInPrimaryColors ? 10.5 : 11.5
            }
          />
          <TableComponent
            label1={"# of B elbows"}
            label2={pricesValues?.costPrice?.bElbows}
            label3={
              pricesValues?.costPrice?.inCLudeInPrimaryColors ? 10.5 : 11.5
            }
          />
          <TableComponent
            label1={"Total Footage (gutter + downspout + elbows)"}
            label2={pricesValues?.costPrice?.totalGutterDownspoutElbows}
            label3={
              pricesValues?.costPrice?.inCLudeInPrimaryColors ? 10.5 : 11.5
            }
          />
          <TableComponent
            label1={"# of Wedges"}
            label2={pricesValues?.costPrice?.ofWedges}
            label3={"1.75"}
          />
          <TableComponent
            label1={"Fascia Total Footage"}
            label2={pricesValues?.costPrice?.fasciaTotalFootage}
            label3={12.5}
          />
          <TableComponent
            label1={"Total FT. of new wrap"}
            label2={pricesValues?.costPrice?.totalFTOfNewWrap}
            label3={9}
          />
          <TableComponent
            label1={"Soffit Total Footage"}
            label2={pricesValues?.costPrice?.soffitTotalFootage}
            label3={12.5}
          />
          {pricesValues?.costPrice?.porchCeiling?.map((item, i) => (
            <TableComponent
              label1={`Location ${i + 1}`}
              label2={item.squareFeet}
              label3={
                pricesValues?.costPrice?.inCLudeInPrimaryColors ? 7.5 : 8.5
              }
            />
          ))}
        </div>
      </div>

      <div className="k-mt-2">
        <h1>Price Calculation (Retail Price)</h1>
        <div style={{ width: "100%", border: "3px solid #8ABD5A" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Lable</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Calculated Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Multiplyed By</b>
            </div>
          </div>
          <TableComponent
            label1={"1st Story Total"}
            label2={pricesValues?.retailPrice?.firstStoryTotal}
            label3={pricesValues?.retailPrice?.inCLudeInPrimaryColors ? 21 : 22}
          />
          <TableComponent
            label1={"2nd  Story Total"}
            label2={pricesValues?.retailPrice?.secondStoryTotal}
            label3={pricesValues?.retailPrice?.inCLudeInPrimaryColors ? 21 : 22}
          />
          <TableComponent
            label1={"3rd  Story Total"}
            label2={pricesValues?.retailPrice?.thirldSotryTotal}
            label3={pricesValues?.retailPrice?.inCLudeInPrimaryColors ? 21 : 22}
          />
          <TableComponent
            label1={"New Meter Box Insdie"}
            label2={pricesValues?.retailPrice?.newMeterInside}
            label3={100}
          />
          <TableComponent
            label1={"New Meter Box OutSide"}
            label2={pricesValues?.retailPrice?.newMeterOutSide}
            label3={100}
          />
          {/* <TableComponent label1={'Gutter Sizes'} label2={pricesValues?.retailPrice?.gutterSize} label3={29}/> */}
          <TableComponent
            label1={"Total Gutter Footage"}
            label2={pricesValues?.retailPrice?.totalGutterFootage}
            label3={
              Number(previewData.gutter_size) == 7
                ? 29
                : pricesValues?.retailPrice?.inCLudeInPrimaryColors
                ? 21
                : 22
            }
          />
          <TableComponent
            label1={"Downspout Footage"}
            label2={pricesValues?.retailPrice?.downspoutFootage}
            label3={pricesValues?.retailPrice?.inCLudeInPrimaryColors ? 21 : 22}
          />
          <TableComponent
            label1={"# of A elbows"}
            label2={pricesValues?.retailPrice?.aElbows}
            label3={pricesValues?.retailPrice?.inCLudeInPrimaryColors ? 21 : 22}
          />
          <TableComponent
            label1={"# of B elbows"}
            label2={pricesValues?.retailPrice?.bElbows}
            label3={pricesValues?.retailPrice?.inCLudeInPrimaryColors ? 21 : 22}
          />
          <TableComponent
            label1={"Total Footage (gutter + downspout + elbows)"}
            label2={pricesValues?.retailPrice?.totalGutterDownspoutElbows}
            label3={pricesValues?.retailPrice?.inCLudeInPrimaryColors ? 21 : 22}
          />
          <TableComponent
            label1={"# of Wedges"}
            label2={pricesValues?.retailPrice?.ofWedges}
            label3={"3.5"}
          />
          <TableComponent
            label1={"Fascia Total Footage"}
            label2={pricesValues?.retailPrice?.fasciaTotalFootage}
            label3={25}
          />
          <TableComponent
            label1={"Total FT. of new wrap"}
            label2={pricesValues?.retailPrice?.totalFTOfNewWrap}
            label3={18}
          />
          <TableComponent
            label1={"Soffit Total Footage"}
            label2={pricesValues?.retailPrice?.soffitTotalFootage}
            label3={25}
          />

          {pricesValues?.retailPrice?.porchCeiling?.map((item, i) => (
            <TableComponent
              label1={`Location ${i + 1}`}
              label2={item.squareFeet}
              label3={15}
            />
          ))}
        </div>
      </div>

      <div className="k-mt-2">
        <h1>Totals</h1>
        <div style={{ width: "100%", border: "3px solid #8ABD5A" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Retail Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>
                $
                {(pricesValues?.retailPrice?.newMeterInside || 0) +
                  (pricesValues?.retailPrice?.newMeterOutSide || 0) +
                  (pricesValues?.retailPrice?.totalGutterDownspoutElbows || 0) +
                  (pricesValues?.retailPrice?.ofWedges || 0) +
                  (pricesValues?.retailPrice?.fasciaTotalFootage || 0) +
                  (pricesValues?.retailPrice?.totalFTOfNewWrap || 0) +
                  (pricesValues?.retailPrice?.soffitTotalFootage || 0) +
                  (pricesValues?.retailPrice?.porchCeiling?.reduce(
                    (sum, item) => sum + item.squareFeet,
                    0
                  ) || 0) +
                  500}
              </b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b></b>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>PAR Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>
                $
                {((pricesValues?.retailPrice?.newMeterInside || 0) +
                  (pricesValues?.retailPrice?.newMeterOutSide || 0) +
                  (pricesValues?.retailPrice?.totalGutterDownspoutElbows || 0) +
                  (pricesValues?.retailPrice?.ofWedges || 0) +
                  (pricesValues?.retailPrice?.fasciaTotalFootage || 0) +
                  (pricesValues?.retailPrice?.totalFTOfNewWrap || 0) +
                  (pricesValues?.retailPrice?.soffitTotalFootage || 0) +
                  (pricesValues?.retailPrice?.porchCeiling?.reduce(
                    (sum, item) => sum + item.squareFeet,
                    0
                  ) || 0) +
                  500) *
                  0.65}
              </b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b></b>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Cost Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>
                $
                {(pricesValues?.costPrice?.newMeterInside || 0) +
                  (pricesValues?.costPrice?.newMeterOutSide || 0) +
                  (pricesValues?.costPrice?.totalGutterDownspoutElbows || 0) +
                  (pricesValues?.costPrice?.ofWedges || 0) +
                  (pricesValues?.costPrice?.fasciaTotalFootage || 0) +
                  (pricesValues?.costPrice?.totalFTOfNewWrap || 0) +
                  (pricesValues?.costPrice?.soffitTotalFootage || 0) +
                  (pricesValues?.costPrice?.porchCeiling?.reduce(
                    (sum, item) => sum + item.squareFeet,
                    0
                  ) || 0)}
              </b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;

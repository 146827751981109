import { useState } from "react";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";

function PartialInstallSelection() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const { previewData,  setFormData } = usePreviewStore();
  const isPartialInstall = previewData.partial_install;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      partial_install: value
     });
  };

  return (
    <div className="k-display-flex k-flex-wrap k-gap-4 k-sm-gap-2">
      <h4 className="k-m-0 k-font-light k-pt-2 k-pt-sm-4">Partial install?</h4>
      
      <div className="k-display-flex k-gap-2 k-pos-relative k-top-0 k-sm-top-2">
        <label className="k-m-0 k-font-light k-pt-2 k-mr-4">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="partialInstall"
            value="Yes"
            checked={isPartialInstall === "Yes"}
            onChange={() => handleInputChange("Yes")}
          />
          Yes
        </label>

        <label className="k-m-0 k-font-light k-pt-2">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="partialInstall"
            value="No"
            checked={isPartialInstall === "No"}
            onChange={() => handleInputChange("No")}
          />
          No
        </label>
      </div>
    </div>
  );
}

export default PartialInstallSelection;

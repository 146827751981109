import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function FastenerSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const selectedFastener = orderData.mountingType;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      mountingType: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  return (
    <div>
    <div className="k-display-flex k-flex-wrap k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2 k-sm-w-100">Fastener Type</h4>
      {/* <div className="k-pos-relative k-top-1" style={{ width: "100px" }}>
        <Input value={orderData.mountingTypeValue} onChange={handleInputChange2('mountingTypeValue')}/>
      </div> */}
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4 k-ml-sm-0 k-d-flex-sm-cloumn">
        <label>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Hidden Hangers"
            checked={selectedFastener === "Hidden Hangers"}
            onChange={() => handleInputChange("Hidden Hangers")}
            style={{ marginRight: "10px" }}
          />
          Hidden Hangers
        </label>
        
        <label>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Spikes"
            checked={selectedFastener === "Spikes"}
            onChange={() => handleInputChange("Spikes")}
            style={{ marginRight: "10px" }}
          />
          Spikes
        </label>
        
        <label className="k-ml-sm-0" style={{ margin: "0 8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="T Straps"
            checked={selectedFastener === "T Straps"}
            onChange={() => handleInputChange("T Straps")}
            style={{ marginRight: "10px" }}
            className="k-ml-sm-0"
          />
          T Straps
        </label>
        
        <label className="k-ml-sm-0" style={{ margin: "0 8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Wedges"
            checked={selectedFastener === "Wedges"}
            onChange={() => handleInputChange("Wedges")}
            style={{ marginRight: "10px" }}
            className="k-ml-sm-0"
          />
          Wedges
        </label>
        
        <label className="k-ml-sm-0" style={{ margin: "0 8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastener"
            value="Rival"
            checked={selectedFastener === "Rival Straps"}
            onChange={() => handleInputChange("Rival Straps")}
            style={{ marginRight: "10px" }}
            className="k-ml-sm-0"
          />
          Rival Straps
        </label>
      </h4>
    </div>
    <div className="k-display-flex k-flex-wrap k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2 k-display-flex" style={{flexWrap:"wrap"}}>
        <label style={{marginRight:"10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastenerType"
            value="Rival Bar"
            checked={selectedFastener === "Rival Bar"}
            onChange={() => handleInputChange("Rival Bar")}
            style={{ marginRight: "10px",marginTop:"10px" }}
          />
          Rival Bar
        </label>
        <label style={{marginRight:"10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastenerType"
            value="Sickle & Shank"
            checked={selectedFastener === "Sickle & Shank"}
            onChange={() => handleInputChange("Sickle & Shank")}
            style={{ marginRight: "10px",marginTop:"10px" }}
          />
          Sickle & Shank
        </label>
        <label style={{marginRight:"10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastenerType"
            value="Fascia Hanger"
            checked={selectedFastener === "Fascia Hanger"}
            onChange={() => handleInputChange("Fascia Hanger")}
            style={{ marginRight: "10px",marginTop:"10px"}}
          />
          Fascia Hanger
        </label>
        <label style={{marginRight:"10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="fastenerType"
            value="T-Wedge"
            checked={selectedFastener === "T-Wedge"}
            onChange={() => handleInputChange("T-Wedge")}
            style={{ marginRight: "10px",marginTop:"10px" }}
          />
          T-Wedge
        </label>
      </h4>
      
      <div className="k-pos-relative k-d-flex k-gap-2 k-top-1 k-ml-4 k-mt-3 k-sm-w-90" style={{ width: "100px" }}>
        <Input type="number" value={orderData.mountingLength} onChange={handleInputChange2('mountingLength')}/>
        <h4 className="k-pos-relative k-bottom-3 k-m-0 k-font-light k-ml-3 k-mt-3">
        FT.
      </h4>
      </div>

      
    </div>
      
    </div>
  );
}

export default FastenerSelection;

import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";

const SingleLeadToolBar = () => {
  function formatTodayDate() {
    const today = new Date();
    
    // Array of weekday names
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
    // Get day name
    const dayName = daysOfWeek[today.getDay()];
    
    // Get month, day, and year
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
    const day = String(today.getDate()).padStart(2, '0'); // Ensure 2-digit day
    const year = today.getFullYear();
    
    return `${dayName} ${month}/${day}/${year}`;
}
  return (
    <div>
      <h3>LEADS FOR:</h3>
      <h1>{formatTodayDate()}</h1>
    <div className="k-display-flex">
    <div className="k-mb-6">
        <Button
          primary={true}
          className="k-button-blue"
          size="large"
        >
          Lead Summary
        </Button>
      </div>
      <div className="k-mb-6 k-mx-3">
        <Button
          primary={true}
          className="k-button-blue"
          size="large"
        >
          Refresh Leads
        </Button>
      </div>
    </div>
    </div>
  );
};

export default SingleLeadToolBar;

import { useState } from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";

function FasciaReplacementSelection() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const { previewData,  setFormData } = usePreviewStore();
  const fasciaReplacement = previewData.fascia_replacement;
  const handleInputChange = (value) => {
    setFormData({ 
      ...previewData,
      fascia_replacement: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-d-flex k-flex-wrap k-grid-cols-1 k-grid-sm-cols-1 k-mt-4">
      <h4 className="k-flex-basis-auto k-d-flex k-m-0 k-w-30 k-w-sm-100">Fascia Replacement:</h4>

      <div className="k-display-flex k-flex-wrap k-gap-2">
        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-15">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="fasciaReplacement"
            value="Full"
            checked={fasciaReplacement === "Full"}
            onChange={() => handleInputChange("Full")}
          />
          Full
        </label>


        <label className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-15">
          <input
          disabled={isDisabled}
          style={{marginRight:"10px"}}
            type="radio"
            name="fasciaReplacement"
            value="Partial"
            checked={fasciaReplacement === "Partial"}
            onChange={() => handleInputChange("Partial")}
          />
          Partial
        </label>

        <h4 className="k-flex-basis-auto k-d-flex k-m-0 k-w-27 k-pl-9  k-font-light">Total Footage:</h4>

        <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-30 k-font-light k-mt-0">
          <Input   
          disable={true}
          value={previewData.fascia_total_footage}
          onChange={handleInputChange2("fascia_total_footage")}
          />
        </div>
      </div>
    </div>
  );
}

export default FasciaReplacementSelection;

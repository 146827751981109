import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import Input from "../../components/preview-input/input";
import FilterColors from "./filter-colors";
import FilterProfile from "./filter-profile";
import MainDetails from "./main-details";
import OrderProfile from "./order-profile";
import Story from "./story";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DrawingBox from "./drawing-box";
import Extensions from "./extensions";
import useOrderStore from "../../store/order-store";
import axiosInstance, { endpoints } from "../../utils/axios";
import Success from "../../components/success/success";
import { HOST_API } from "../../config-global";
import { convertErrorsToArray } from "../../utils/formats";
import { toast } from "react-toastify";
import axios from "axios";
import useResultStore from "../../store/result-store";
import { Loader } from "@progress/kendo-react-indicators";
import TableComponent from "../preview/table-component";
import { getDataForSf } from "./order-utils";

const OrderSheet = () => {
  const { id } = useParams();
  const { orderData, setFormData, reset } = useOrderStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leadId = queryParams.get("leadId");
  const { resultData } = useResultStore();
  const [calculationData, setCalculationData] = useState({});
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value,
    });
  };
  useEffect(() => {
    getSheet();
  }, []);

  const salesForceLogin = async (payload) => {
    try {
      const response = await axiosInstance.get(endpoints.salesForce.login);
      const finnnalResult = await salesForceOrderSheet(
        response.data.access_token,
        payload
      );
      return finnnalResult;
    } catch (error) {
      console.error(
        "Error Logging into Salesforce:",
        error.response?.data || error.message
      );
    }
  };

  const salesForceOrderSheet = async (token, data) => {
    try {
      const response = await axios.post(
        `https://abc742-dev-ed.develop.my.salesforce.com/services/apexrest/api/OpportunityMeasurementsAPI/`,
        JSON.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      );
      return response;
      // console.log("Salesforce Login Success:", response.data);
    } catch (error) {
      return error;
    }
  };

  const getSheet = async () => {
    try {
      const response = await axiosInstance.get(
        endpoints.leads.get_order_detail(leadId)
      );
      if (response.data) {
        console.log(response.data.data);
        let data = response.data.data;
        const openingWidthValue = data?.gutterOpeningWidth?.find(
          (item) => !["4", "4.5", "5", "5.5", "6", "7", "8"].includes(item)
        );
        if (data.customer) {
          setShowButton(false);
          setFormData({
            ...data,
            partial: data.partial ? "Yes" : "No",
            existingProtection: data.existingProtection ? "Yes" : "No",
            roof: data.roof ? "Yes" : "No",
            csr_value: data?.csrValue,
            extensionsNeeded: data.extensionsNeeded ? "Yes" : "No",
            groundSpoutNeeded: data.groundSpoutNeeded ? "Yes" : "No",
            gutterOpeningWidth: data.gutterOpeningWidth,
            gutterOpeningWidthValue: openingWidthValue,
            existingProtectionTypeValue:
              data.existingProtectionType == "other"
                ? data.existingProtectionTypeValue
                : 0,
            walkboards: data.walkboards ? "Yes" : "No",
            roofType:
              data.roofType != "Flat Roof" &&
              data.roofType != "Wood Shake" &&
              data.roofType != "Asphalt Shingle" &&
              data.roofType != "Metal" &&
              data.roofType != "Tile" &&
              data.roofType != "Slate"
                ? "Other"
                : data.roofType,
            roofTypeValue:
              data.roofType != "Flat Roof" &&
              data.roofType != "Wood Shake" &&
              data.roofType != "Asphalt Shingle" &&
              data.roofType != "Metal" &&
              data.roofType != "Tile" &&
              data.roofType != "Slate"
                ? data.roofType
                : "",
            ladders:
              data.ladders != "8" &&
              data.ladders != "10" &&
              data.ladders != "24" &&
              data.ladders != "28" &&
              data.ladders != "32" &&
              data.ladders != "40"
                ? "Other"
                : data?.ladders?.toString(),
            laddersValue:
              data.ladders != "8" &&
              data.ladders != "10" &&
              data.ladders != "24" &&
              data.ladders != "28" &&
              data.ladders != "32" &&
              data.ladders != "40"
                ? data.ladders
                : "",
            job: leadId,
            versa_max_quantity: data.versaMaxValue,
            hasSheet: true,
          });
          setLoading(false);
        } else {
          // reset();
          setShowButton(true);
          setFormData({
            ...orderData,
            hasSheet: false,
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };
  function removeEmptyStrings(obj) {
    for (const key in obj) {
      if (obj[key] === "") {
        delete obj[key];
      }
    }
    return obj;
  }
  function convertEmptyStringsToZero(data) {
    if (Array.isArray(data)) {
      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            item[key] = item[key].map((value) => (value === "" ? 0 : value));
          }
        });
      });
    }
    return data;
  }
  function convertInvalidValuesToZero(data) {
    if (Array.isArray(data)) {
      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            item[key] = item[key].map((value) =>
              value === "" || value === null ? 0 : value
            );
          }
        });
      });
    }
    return data;
  }

  function replaceNullWithEmptyString(obj) {
    for (const key in obj) {
      if (obj[key] === null) {
        obj[key] = ""; // Replace null with an empty string
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        // Skip objects without modifying them
        continue;
      }
    }
    return obj;
  }

  const onSubmit = async () => {
    setLoading(true);
    if (resultData.lastPrice == "") {
      resultData.lastPrice = 0;
    }
    let finnnalWidths = orderData.gutterOpeningWidthValue
      ? [
          ...orderData.gutterOpeningWidth,
          orderData.gutterOpeningWidthValue.toString(),
        ]
      : orderData.gutterOpeningWidth;
    const subdata = {
      ...orderData,
      partial: orderData.partial == "Yes" ? true : false,
      existingProtection: orderData.existingProtection == "Yes" ? true : false,
      existingProtectionType:
        orderData.existingProtection == "Yes" &&
        orderData.existingProtectionType,
      existingProtectionTypeValue:
        orderData.existingProtection === "Yes"
          ? Number(orderData.existingProtectionTypeValue)
          : 0,
      roof: orderData.roof == "Yes" ? true : false,
      extensionsNeeded: orderData.extensionsNeeded == "Yes" ? true : false,
      groundSpoutNeeded: orderData.groundSpoutNeeded == "Yes" ? true : false,
      walkboards: orderData.walkboards == "Yes" ? true : false,
      csrValue: orderData?.csr_value?.toString() || "",
      gutterOpeningWidth: finnnalWidths,
      roofType:
        orderData.roofType == "Other"
          ? orderData.roofTypeValue
          : orderData.roofType,
      ladders:
        orderData.ladders == "Other"
          ? orderData.laddersValue
          : orderData.ladders,
      leadId: leadId,
      results: resultData,
      totalFootage: orderData?.totalFootage?.toString(),
      versaMaxValue: orderData.leafFilterProfile.includes("Versa Max")
        ? orderData?.versa_max_quantity?.toString() || ""
        : "",
    };

    delete subdata.images;
    delete subdata.gutterOpeningWidthValue;

    let customSubdata = replaceNullWithEmptyString(subdata);
    const sfData = removeEmptyStrings(subdata);
    sfData.measurements = convertEmptyStringsToZero(sfData.measurements);
    sfData.sizes = convertInvalidValuesToZero(sfData.sizes);
    sfData.versaMaxValue = Number(sfData.versaMaxValue) || 0;
    const genrateDataForSf = getDataForSf(sfData);
    console.log(genrateDataForSf, "here it is");
    setCalculationData(genrateDataForSf);
    setLoading(false);
    return;
    // try {
    //   const response = await axios.post(
    //     `${HOST_API}${endpoints.leads.post_order}`,
    //     customSubdata,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   const sfData = removeEmptyStrings(subdata);
    //   sfData.measurements = convertEmptyStringsToZero(sfData.measurements);
    //   sfData.sizes = convertInvalidValuesToZero(sfData.sizes);
    //   sfData.versaMaxValue = Number(sfData.versaMaxValue) || 0;
    //   const genrateDataForSf=getDataForSf(sfData)
    //   const result = await salesForceLogin(sfData);
    //   console.log(result);
    //   if (!result?.data?.startsWith("Opportunity")) {
    //     toast.error("Error in SF API");
    //   }
    //   setLoading(false);
    //   console.log("🚀 ~ onSubmit ~ response:", response.data);
    //   console.log("Submitted Data:", subdata);
    //   setShowSuccess(true);
    //   setTimeout(() => {
    //     setShowSuccess(false);
    //     navigate(`/`);
    //   }, 2000);
    // } catch (error) {
    //   setLoading(false);
    //   console.error("Error submitting data:", error?.response?.data?.data);
    //   const errorsArray = convertErrorsToArray(error?.response?.data?.data);

    //   if (errorsArray.length > 0) {
    //     errorsArray.forEach((err) => toast.error(err));
    //   } else if (error.message) {
    //     toast.error(error.message);
    //   } else {
    //     toast.error("Internal Server Error");
    //   }
    // }
  };

  return (
    <div className="">
      <h2 className="k-py-4 k-font-bold">Preview</h2>
      <div className="k-rounded-lg k-p-4" style={{ background: "#FFFFFF" }}>
        <div
          className=" k-border-solid k-border k-border-black k-sm-w-100"
          style={{ width: "30%" }}
        >
          <h2 className="k-m-0 k-py-3 k-px-3">LeafFilter Order Sheet</h2>
        </div>
        <div className="k-border-solid k-border k-border-black k-mt-1">
          <MainDetails id={id} />
        </div>
        <div className="k-border-solid k-border k-border-black">
          <OrderProfile />
        </div>
        <div className="k-border-solid k-border k-border-black">
          <FilterProfile />
        </div>
        <div className="k-px-2 k-border-solid k-border k-border-black  k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
          <Story />

          <FilterColors />
        </div>

        <div className="k-px-2 k-border-solid k-border k-border-black k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
          <Extensions />

          {/* second next section */}
          <DrawingBox />
        </div>
        <div className=" k-px-2 k-border-solid k-border k-border-black">
          <h4 className="k-m-0 k-p-3 k-display-flex k-gap-2">
            <u>Note: </u>
            <Input
              value={orderData.note}
              onChange={handleInputChange2("note")}
            />
          </h4>
        </div>
      </div>
      {showButton ? (
        <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
          <Button
            onClick={() => navigate(`/leads/${id}`)}
            primary={true}
            className="k-button k-mr-2"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            primary={true}
            className="k-button-green k-mr-2"
            size="large"
          >
            {loading ? (
              <Loader
                size="small"
                type="converging-spinner"
                style={{ color: "white" }}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      ) : (
        <div className="k-w-full k-d-flex k-justify-content-end k-my-8">
          <Button
            onClick={onSubmit}
            // onClick={() => navigate(`/leads/${id}`)}
            primary={true}
            className="k-button k-mr-2"
            size="large"
          >
            Go Back
          </Button>
        </div>
      )}
      {showSuccess && (
        <Success
          visible={showSuccess}
          toggleDialog={() => setShowSuccess(false)}
        />
      )}
      <div className="k-mt-2">
        <h1>Price Calculation (Cost Price)</h1>
        <div style={{ width: "100%", border: "3px solid #8ABD5A" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Lable</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Calculated Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Multiplyed By</b>
            </div>
          </div>
          <TableComponent
            label1={"1st Story Total 1"}
            label2={calculationData?.costPrice?.firstStoryTotal1}
            label3={16}
          />
          <TableComponent
            label1={"1st Story Total 2"}
            label2={calculationData?.costPrice?.firstStoryTotal2}
            label3={16}
          />
          <TableComponent
            label1={"2nd  Story Total 1"}
            label2={calculationData?.costPrice?.secondStoryTotal1}
            label3={16}
          />
          <TableComponent
            label1={"2nd  Story Total 2"}
            label2={calculationData?.costPrice?.secondStoryTotal2}
            label3={16}
          />
          <TableComponent
            label1={"3rd  Story Total 1"}
            label2={calculationData?.costPrice?.thirdStoryTotal1}
            label3={16}
          />
          <TableComponent
            label1={"3rd  Story Total 2"}
            label2={calculationData?.costPrice?.thirdStoryTotal2}
            label3={16}
          />
          <TableComponent
            label1={"Total Footage"}
            label2={
              calculationData?.costPrice?.firstStoryTotal1 +
              calculationData?.costPrice?.firstStoryTotal2 +
              calculationData?.costPrice?.secondStoryTotal1 +
              calculationData?.costPrice?.secondStoryTotal2 +
              calculationData?.costPrice?.thirdStoryTotal1 +
              calculationData?.costPrice?.thirdStoryTotal2
            }
            label3={16}
          />
          <TableComponent
            label1={"1st Story R & R Total 1"}
            label2={calculationData?.costPrice?.firstStoryRAndRTotal1}
            label3={2}
          />
          <TableComponent
            label1={"1st Story R & R Total 2"}
            label2={calculationData?.costPrice?.firstStoryRAndRTotal2}
            label3={2}
          />
          <TableComponent
            label1={"2nd  Story R & R Total 1"}
            label2={calculationData?.costPrice?.secondStoryRAndRTotal1}
            label3={2}
          />
          <TableComponent
            label1={"2nd  Story R & R Total 2"}
            label2={calculationData?.costPrice?.secondStoryRAndRTotal2}
            label3={2}
          />
          <TableComponent
            label1={"3rd  Story R & R Total 1"}
            label2={calculationData?.costPrice?.thirdStoryRAndRTotal1}
            label3={2}
          />
          <TableComponent
            label1={"3rd  Story R & R Total 2"}
            label2={calculationData?.costPrice?.thirdStoryRAndRTotal2}
            label3={2}
          />
          <TableComponent
            label1={"Versa Max Quantity"}
            label2={calculationData?.costPrice?.versaMaxQuantity}
            label3={27.5}
          />
          <TableComponent
            label1={calculationData?.mountingType || "Hidden Hangers"}
            label2={calculationData?.costPrice?.fatnerType}
            label3={
              calculationData.mountingType == "T Straps" ||
              calculationData.mountingType == "Straps"
                ? 3
                : 1
            }
          />
          <TableComponent
            label1={"Clean, Seal, Reinforce"}
            label2={calculationData?.costPrice?.cleanSealReinforce}
            label3={3.5}
          />
          <TableComponent
            label1={"of Inside Corners"}
            label2={calculationData?.costPrice?.ofInsideCorners}
            label3={12.5}
          />
          <TableComponent
            label1={"of Outside Corners"}
            label2={calculationData?.costPrice?.ofOutSideCorners}
            label3={12.5}
          />
          <TableComponent
            label1={"Type of Existing Protection"}
            label2={calculationData?.costPrice?.type}
            label3={1.75}
          />
          <TableComponent
            label1={"Extension Needed feet"}
            label2={calculationData?.costPrice?.extensionsNeededFootage}
            label3={calculationData?.costPrice?.isColor ? 10.5 : 11}
          />
          <TableComponent
            label1={"Groundspout Needed"}
            label2={calculationData?.costPrice?.units}
            label3={25}
          />
          <TableComponent
            label1={"Of A Elbows"}
            label2={calculationData?.costPrice?.ofAElbows}
            label3={calculationData?.costPrice?.isColor ? 10.5 : 11}
          />
          <TableComponent
            label1={"Of A Elbows"}
            label2={calculationData?.costPrice?.ofBElbows}
            label3={calculationData?.costPrice?.isColor ? 10.5 : 11}
          />
          {calculationData?.costPrice?.sizes?.map((item) => (
            <TableComponent
              label1={`Size ${item.label}`}
              label2={`${item.values[0]},${item.values[1]},${item.values[2]},${item.values[3]}`}
              label3={calculationData?.costPrice?.isColor ? 10.5 : 11}
            />
          ))}
        </div>
      </div>

      <div className="k-mt-2">
        <h1>Price Calculation (Retail Price)</h1>
        <div style={{ width: "100%", border: "3px solid #8ABD5A" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Lable</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Calculated Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Multiplyed By</b>
            </div>
          </div>
          <TableComponent
            label1={"1st Story Total 1"}
            label2={calculationData?.retailPrice?.firstStoryTotal1}
            label3={38}
          />
          <TableComponent
            label1={"1st Story Total 2"}
            label2={calculationData?.retailPrice?.firstStoryTotal2}
            label3={39}
          />
          <TableComponent
            label1={"2nd  Story Total 1"}
            label2={calculationData?.retailPrice?.secondStoryTotal1}
            label3={40}
          />
          <TableComponent
            label1={"2nd  Story Total 2"}
            label2={calculationData?.retailPrice?.secondStoryTotal2}
            label3={41}
          />
          <TableComponent
            label1={"3rd  Story Total 1"}
            label2={calculationData?.retailPrice?.thirdStoryTotal1}
            label3={42}
          />
          <TableComponent
            label1={"3rd  Story Total 2"}
            label2={calculationData?.retailPrice?.thirdStoryTotal2}
            label3={43}
          />
          <TableComponent
            label1={"Total Footage"}
            label2={
              calculationData?.retailPrice?.firstStoryTotal1 +
              calculationData?.retailPrice?.firstStoryTotal2 +
              calculationData?.retailPrice?.secondStoryTotal1 +
              calculationData?.retailPrice?.secondStoryTotal2 +
              calculationData?.retailPrice?.thirdStoryTotal1 +
              calculationData?.retailPrice?.thirdStoryTotal2
            }
            label3={"Sum Of Calculated Stories"}
          />
          <TableComponent
            label1={"1st Story R & R Total 1"}
            label2={calculationData?.retailPrice?.firstStoryRAndRTotal1}
            label3={4}
          />
          <TableComponent
            label1={"1st Story R & R Total 2"}
            label2={calculationData?.retailPrice?.firstStoryRAndRTotal2}
            label3={4}
          />
          <TableComponent
            label1={"2nd  Story R & R Total 1"}
            label2={calculationData?.retailPrice?.secondStoryRAndRTotal1}
            label3={4}
          />
          <TableComponent
            label1={"2nd  Story R & R Total 2"}
            label2={calculationData?.retailPrice?.secondStoryRAndRTotal2}
            label3={4}
          />
          <TableComponent
            label1={"3rd  Story R & R Total 1"}
            label2={calculationData?.retailPrice?.thirdStoryRAndRTotal1}
            label3={4}
          />
          <TableComponent
            label1={"3rd  Story R & R Total 2"}
            label2={calculationData?.retailPrice?.thirdStoryRAndRTotal2}
            label3={4}
          />
          <TableComponent
            label1={"Versa Max Quantity"}
            label2={calculationData?.retailPrice?.versaMaxQuantity}
            label3={55}
          />
          <TableComponent
            label1={calculationData?.mountingType || "Hidden Hangers"}
            label2={calculationData?.retailPrice?.fatnerType}
            label3={
              calculationData.mountingType == "T Straps" ||
              calculationData.mountingType == "Straps"
                ? 3
                : 1
            }
          />
          <TableComponent
            label1={"Clean, Seal, Reinforce"}
            label2={calculationData?.retailPrice?.cleanSealReinforce}
            label3={7}
          />
          <TableComponent
            label1={"of Inside Corners"}
            label2={calculationData?.retailPrice?.ofInsideCorners}
            label3={25}
          />
          <TableComponent
            label1={"of Outside Corners"}
            label2={calculationData?.retailPrice?.ofOutSideCorners}
            label3={25}
          />
          <TableComponent
            label1={"Type of Existing Protection"}
            label2={calculationData?.retailPrice?.type}
            label3={3}
          />
          <TableComponent
            label1={"Extension Needed feet"}
            label2={calculationData?.retailPrice?.extensionsNeededFootage}
            label3={25}
          />
          <TableComponent
            label1={"Groundspout Needed"}
            label2={calculationData?.retailPrice?.units}
            label3={50}
          />
          <TableComponent
            label1={"Of A Elbows"}
            label2={calculationData?.retailPrice?.ofAElbows}
            label3={calculationData?.retailPrice?.isColor ? 21 : 22}
          />
          <TableComponent
            label1={"Of A Elbows"}
            label2={calculationData?.retailPrice?.ofBElbows}
            label3={calculationData?.retailPrice?.isColor ? 21 : 22}
          />
          {calculationData?.retailPrice?.sizes?.map((item) => (
            <TableComponent
              label1={`Size ${item.label}`}
              label2={`${item.values[0]},${item.values[1]},${item.values[2]},${item.values[3]}`}
              label3={calculationData?.retailPrice?.isColor ? 21 : 22}
            />
          ))}
        </div>
      </div>

      <div className="k-mt-2">
        <h1>Totals</h1>
        <div style={{ width: "100%", border: "3px solid #8ABD5A" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Retail Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>
                $
                {(calculationData?.retailPrice?.firstStoryTotal1 || 0) +
                  (calculationData?.retailPrice?.firstStoryTotal2 || 0) +
                  (calculationData?.retailPrice?.secondStoryTotal1 || 0) +
                  (calculationData?.retailPrice?.secondStoryTotal2 || 0) +
                  (calculationData?.retailPrice?.thirdStoryTotal1 || 0) +
                  (calculationData?.retailPrice?.thirdStoryTotal2 || 0) +
                  (calculationData?.retailPrice?.firstStoryRAndRTotal1 || 0) +
                  (calculationData?.retailPrice?.firstStoryRAndRTotal2 || 0) +
                  (calculationData?.retailPrice?.secondStoryRAndRTotal1 || 0) +
                  (calculationData?.retailPrice?.secondStoryRAndRTotal2 || 0) +
                  (calculationData?.retailPrice?.thirdStoryRAndRTotal1 || 0) +
                  (calculationData?.retailPrice?.thirdStoryRAndRTotal2 || 0) +
                  (calculationData?.retailPrice?.versaMaxQuantity || 0) +
                  (calculationData?.retailPrice?.fatnerType || 0) +
                  (calculationData?.retailPrice?.cleanSealReinforce || 0) +
                  (calculationData?.retailPrice?.ofInsideCorners || 0) +
                  (calculationData?.retailPrice?.ofOutSideCorners || 0) +
                  (calculationData?.retailPrice?.type || 0) +
                  (calculationData?.retailPrice?.extensionsNeededFootage || 0) +
                  (calculationData?.retailPrice?.units || 0) +
                  (calculationData?.retailPrice?.ofAElbows || 0) +
                  (calculationData?.retailPrice?.ofBElbows || 0) +
                  (calculationData?.retailPrice?.sizes?.reduce(
                    (total, item) =>
                      total +
                      (item.values?.reduce(
                        (subTotal, num) => subTotal + num,
                        0
                      ) || 0),
                    0
                  ) || 0) +
                  500}
              </b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b></b>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>PAR Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>
                $
                {((calculationData?.retailPrice?.firstStoryTotal1 || 0) +
                  (calculationData?.retailPrice?.firstStoryTotal2 || 0) +
                  (calculationData?.retailPrice?.secondStoryTotal1 || 0) +
                  (calculationData?.retailPrice?.secondStoryTotal2 || 0) +
                  (calculationData?.retailPrice?.thirdStoryTotal1 || 0) +
                  (calculationData?.retailPrice?.thirdStoryTotal2 || 0) +
                  (calculationData?.retailPrice?.firstStoryRAndRTotal1 || 0) +
                  (calculationData?.retailPrice?.firstStoryRAndRTotal2 || 0) +
                  (calculationData?.retailPrice?.secondStoryRAndRTotal1 || 0) +
                  (calculationData?.retailPrice?.secondStoryRAndRTotal2 || 0) +
                  (calculationData?.retailPrice?.thirdStoryRAndRTotal1 || 0) +
                  (calculationData?.retailPrice?.thirdStoryRAndRTotal2 || 0) +
                  (calculationData?.retailPrice?.versaMaxQuantity || 0) +
                  (calculationData?.retailPrice?.fatnerType || 0) +
                  (calculationData?.retailPrice?.cleanSealReinforce || 0) +
                  (calculationData?.retailPrice?.ofInsideCorners || 0) +
                  (calculationData?.retailPrice?.ofOutSideCorners || 0) +
                  (calculationData?.retailPrice?.type || 0) +
                  (calculationData?.retailPrice?.extensionsNeededFootage || 0) +
                  (calculationData?.retailPrice?.units || 0) +
                  (calculationData?.retailPrice?.ofAElbows || 0) +
                  (calculationData?.retailPrice?.ofBElbows || 0) +
                  (calculationData?.retailPrice?.sizes?.reduce(
                    (total, item) =>
                      total +
                      (item.values?.reduce(
                        (subTotal, num) => subTotal + num,
                        0
                      ) || 0),
                    0
                  ) || 0) + 500) *
                  0.65}
              </b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b></b>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>Cost Price</b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b>
                $
                {(
  (calculationData?.costPrice?.firstStoryTotal1 || 0) +
  (calculationData?.costPrice?.firstStoryTotal2 || 0) +
  (calculationData?.costPrice?.secondStoryTotal1 || 0) +
  (calculationData?.costPrice?.secondStoryTotal2 || 0) +
  (calculationData?.costPrice?.thirdStoryTotal1 || 0) +
  (calculationData?.costPrice?.thirdStoryTotal2 || 0) + 
  (calculationData?.costPrice?.firstStoryRAndRTotal1 || 0) +
  (calculationData?.costPrice?.firstStoryRAndRTotal2 || 0) + 
  (calculationData?.costPrice?.secondStoryRAndRTotal1 || 0) +
  (calculationData?.costPrice?.secondStoryRAndRTotal2 || 0) + 
  (calculationData?.costPrice?.thirdStoryRAndRTotal1 || 0) +
  (calculationData?.costPrice?.thirdStoryRAndRTotal2 || 0) + 
  (calculationData?.costPrice?.versaMaxQuantity || 0) +
  (calculationData?.costPrice?.fatnerType || 0) + 
  (calculationData?.costPrice?.cleanSealReinforce || 0) +
  (calculationData?.costPrice?.ofInsideCorners || 0) +
  (calculationData?.costPrice?.ofOutSideCorners || 0) +
  (calculationData?.costPrice?.type || 0) +
  (calculationData?.costPrice?.extensionsNeededFootage || 0) +
  (calculationData?.costPrice?.units || 0) +
  (calculationData?.costPrice?.ofAElbows || 0) +
  (calculationData?.costPrice?.ofBElbows || 0) +
  (calculationData?.costPrice?.sizes?.reduce(
    (total, item) => total + (item.values?.reduce((subTotal, num) => subTotal + num, 0) || 0), 
    0
  ) || 0)
)}
              </b>
            </div>
            <div
              style={{
                minHeight: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <b></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSheet;

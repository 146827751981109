import React from "react";
import Input from "../../components/preview-input/input";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";

const MeasurementsLeft = () => {
  const { previewData, setFormData } = usePreviewStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value
    });
  };
  const handleInputChange = (index, field) => (e) => {
    const newMeasurements = previewData.measurements.map((measurement, i) =>
      i === index ? { ...measurement, [field]: parseFloat(e.target.value) || 0 } : measurement
    );
    const grandTotal = newMeasurements.reduce((total, measurement) => {
      return (
        total +
        (parseFloat(measurement.firstStory) || 0) +
        (parseFloat(measurement.secondStory) || 0) +
        (parseFloat(measurement.thirdStory) || 0)
      );
    }, 0);
    setFormData({ measurements: newMeasurements,total_gutter_footage:grandTotal,fascia_total_footage:grandTotal,soffit_total_footage:grandTotal });
  };
 let measurements= previewData.measurements.slice(0, -1)
  // Calculate totals for each story
  const totals = {
    firstStory: measurements.reduce((sum, measurement) => sum + (parseFloat(measurement.firstStory) || 0), 0),
    secondStory: measurements.reduce((sum, measurement) => sum + (parseFloat(measurement.secondStory) || 0), 0),
    thirdStory: measurements.reduce((sum, measurement) => sum + (parseFloat(measurement.thirdStory) || 0), 0),
  };




  const handleRowClick = (isTotal,index) => {
    if (!isTotal) return; // No need to recalculate for the total row
    const recalculatedMeasurements = previewData.measurements.map((measurement, i) => {
      return i === index
        ? {
            ...measurement,
            firstStory: parseFloat(measurement.firstStory) || 0,
            secondStory: parseFloat(measurement.secondStory) || 0,
            thirdStory: parseFloat(measurement.thirdStory) || 0,
          }
        : measurement;
    });

    const grandTotal = recalculatedMeasurements.reduce((total, measurement) => {
      return (
        total +
        (parseFloat(measurement.firstStory) || 0) +
        (parseFloat(measurement.secondStory) || 0) +
        (parseFloat(measurement.thirdStory) || 0)
      );
    }, 0);

    setFormData({
      measurements: recalculatedMeasurements,
      total_gutter_footage: grandTotal,
      fascia_total_footage: grandTotal,
      soffit_total_footage: grandTotal,
    });
  };
  const renderMeasurementRow = (label, index, isTotal = false) => (
    <div className="k-d-grid k-grid-cols-4 k-grid-sm-cols-1 k-mt-8">
      <h4 style={{cursor:isTotal ? 'pointer' : ''}} className="k-m-0 k-font-light" onClick={()=>handleRowClick(isTotal,index)}>{label}</h4>
      <div className="k-w-sm--100" style={{ width: "80%" }}>
          <Input
          disable={isTotal}
            type="number"
            value={isTotal ? totals.firstStory : previewData.measurements[index]?.firstStory || ""}
            onChange={handleInputChange(index, "firstStory")}
          />
    
      </div>
      <div className="k-w-sm--100" style={{ width: "80%" }}>
          <Input
          disable={isTotal}
            type="number"
            value={isTotal ? totals.secondStory : previewData.measurements[index]?.secondStory || ""}
            onChange={handleInputChange(index, "secondStory")}
          />
      </div>
      <div className="k-w-sm--100" style={{ width: "80%" }}>
    
          <Input
          disable={isTotal}
            type="number"
            value={isTotal ? totals.thirdStory : previewData.measurements[index]?.thirdStory || ""}
            onChange={handleInputChange(index, "thirdStory")}
          />

      </div>
    </div>
  );

  return (
    <div className="k-border-l-0 k-border-t-0 k-border-b-0 k-border-solid k-border k-border-black k-border-r-sm k-pl-6 k-pb-4 k-pt-4 k-sm-pl-0">
      <div className="k-d-grid k-grid-cols-4 k-grid-cols-sm-1 k-grid-sm-cols-1">
        <h4 className="k-font-light">Gutter Height:</h4>
        <h4>0-12 ft.</h4>
        <h4>12.1 ft.- 24ft.</h4>
        <h4>24ft.</h4>
      </div>

      <div className="k-d-grid k-grid-cols-4 k-grid-sm-cols-1">
        <h4>Measurements:</h4>
        <h4>1st Story</h4>
        <h4>2nd Story</h4>
        <h4>3rd Story</h4>
      </div>

      {renderMeasurementRow("Left Side of Home:", 0)}
      {renderMeasurementRow("Right Side of Home:", 1)}
      {renderMeasurementRow("Front of Home:", 2)}
      {renderMeasurementRow("Rear of Home:", 3)}
      {renderMeasurementRow("Garage:", 4)}
      {renderMeasurementRow("Other:", 5)}
      {renderMeasurementRow("Total:", 6, true)}
      <div className="k-d-flex k-gap-10 k-mt-8 k-flex-direction-sm k-sm-gap-2">
      <h4  className="k-m-0 k-font-light" >New Miter Boxes:</h4>
      <div className="k-w-sm--100  k-d-flex k-gap-2" style={{width:"170px"}}>
         <label>Inside</label>
          <Input
          type="number"
                  disabled={isDisabled}
                  value={previewData.newMeterInside}
                  onChange={handleInputChange2("newMeterInside")}
          />
    
      </div>
      <div className="k-w-sm--100 k-d-flex k-gap-2" style={{width:"170px"}}>
      <label>Outside</label>
          <Input
          type="number"
            disabled={isDisabled}
           value={previewData.newMeterOutSide}
           onChange={handleInputChange2("newMeterOutSide")}
          />
      </div>
    </div>
    </div>
  );
};

export default MeasurementsLeft;

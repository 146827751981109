import React, { useEffect, useRef, useState } from "react";
// import Modal from 'react-bootstrap/Modal';
import EntryCard from "./entry-card";
import scan from "../../assets/images/scan.png";
import mannual from "../../assets/images/manual.png";
import { IoCloseSharp } from "react-icons/io5";
import "./Dialog.scss";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import EntryCard2 from "./entry-card2.";
import gutter from "../../assets/images/gutter.png";
import order from "../../assets/images/order.png";
import scanImage from "../../assets/images/scan-image.jpg";
import uploadImage from "../../assets/images/upload-image.jpg";
import axios from "axios";
import usePreviewStore from "../../store/preview-store";
import { useRouter } from "../../pages/routes/hooks/use-router";
import EntryCard3 from "./entry-card3";
import { Upload } from "@progress/kendo-react-upload";
import styles from "../../pages/preview/Drawing.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { toast } from "react-toastify";
import useOrderStore from "../../store/order-store";
import "react-image-crop/dist/ReactCrop.css";
import "./loader.css";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
function Modal({ isOpen, onClose, loading, children }) {
  const dialogRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dialogRef.current &&
      !dialogRef.current.contains(event.target) &&
      isOpen
    ) {
      onClose(false);
    }
  };

  const handleKeydown = (ev) => {
    if (ev.key === "Escape") {
      onClose(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !loading) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeydown);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [isOpen]);

  let portalRoot = document.getElementById("modal");
  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", "modal");
    document.body.appendChild(portalRoot);
  }
  const displayClass = isOpen ? "show" : "hide";

  return ReactDOM.createPortal(
    <div id="modal-comp" className={displayClass} isOpen={isOpen}>
      <div className="dialog" ref={dialogRef} tabIndex={1}>
        {children}
      </div>
    </div>,
    portalRoot
  );
}

function Close(props) {
  return <IoCloseSharp className="close-svg" {...props} />;
}

const DialogSelect = ({ visible, toggleDialog, leadDetail }) => {
  const { id } = useParams();
  const [shiftToArray2, setShiftToArray2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState("arr1");
  const [selectedType, setSelectedType] = useState(null);
  const { previewData, setFormData } = usePreviewStore();
  const { orderData, setFormData: setOrderFormData } = useOrderStore();
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [cropLoading, setCropLoading] = useState(false);
  const [aspect, setAspect] = useState(16 / 9);
  const [scanSheetType, setScanSheetType] = useState(null);
  const [scanType, setScanType] = useState(null);
  const [src, setSrc] = useState(null);
  const [isUsed, setIsUsed] = useState(false);
  // const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [file, setFile] = useState(null);
  const imgRef = useRef(null); // Use useRef for imgRef
  const router = useRouter();
  const arr1Click = (name) => {
    setCurrentView("arr2");
    setSelectedType(name);
  };
  const arr2Click = (item) => {
    console.log(item, "here we go");
    setScanSheetType(item.title);
    if (selectedType == "Scan") {
      setScanType("Upload Image");
      // setCurrentView("arr3");
    } else {
      router.push(item.path);
    }
  };
  const arr3Click = (name) => {
    setScanType(name == "Scan Image" ? "Scan Image" : "Upload Image");
    if (name == "Scan Image") {
      router.push(`/scan/${id}/${leadDetail?.leadId}`);
    }
  };
  const arr1 = [
    {
      image: scan,
      title: "Scan",
      path: `/scan/${id}/${leadDetail?.leadId}`,
    },
    {
      image: mannual,
      title: "Manual",
      path: `/preview/${id}`,
    },
  ];
  const arr2 = [
    {
      image: order,
      title: "Order Sheet",
      path: `/scan-preview/${id}?hasSheet=false&leadId=${leadDetail?.leadId}`,
    },
    {
      image: gutter,
      title: "Gutter Sheet",
      path: `/preview/${id}?hasSheet=false&leadId=${leadDetail?.leadId}`,
    },
  ];
  const arr3 = [
    {
      image: uploadImage,
      title: "Upload Image",
    },
    {
      image: scanImage,
      title: "Scan Image",
    },
  ];

  const handleToggle = () => {
    setShiftToArray2(!shiftToArray2);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Save the first selected file
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedFile) {
      handleSubmittoApi(selectedFile);
    } else {
      console.log("No file selected");
    }
  };
  const handleFileChange2 = (event) => {
    setSelectedFile2(event.target.files[0]); // Save the first selected file
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    if (selectedFile2) {
      console.log("Selected file:", selectedFile2.name);
    } else {
      console.log("No file selected");
    }
  };

  const handleAdd = (event) => {
    setOutput(null);
    setFile(null);
    setSrc(null);
    setIsUsed(false);
    const newFiles = event.affectedFiles.map((fileWrapper) =>
      fileWrapper.getRawFile()
    ); // Convert to File object
    // onSelectFile(newFiles[0]);
    selectImage(newFiles[0]);
    console.log(newFiles[0]);
    setFile(newFiles[0]); // Add to state
  };

  const handleRemove = (event) => {
    setFile(null);
    setImgSrc(null);
    setSrc(null);
    setIsUsed(false);
    setOutput(null);
  };
  function formatTitleCase(text) {
    return text
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const handleSubmittoApi = async (selectedFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await axios.post(
        `https://scanapi.mhalt.com/upload/gutter-sheet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data?.data?.full_analysis;
      const measurementes = response.data.data.upper_analysis;
      console.log(data, "here we goo=====>>>>>>");
      console.log(measurementes, "here we goo=====>>>>>>");
      console.log(response, "here we goo=====>>>>>>");
      const measurementKeys = Object.keys(
        measurementes.Measurements.Columns["0To12ft"]
      );
      const measurements = measurementKeys.map((key) => ({
        firstStory: measurementes.Measurements.Columns["0To12ft"][key] || "",
        secondStory: measurementes.Measurements.Columns["12To24ft"][key] || "",
        thirdStory: measurementes.Measurements.Columns["Over24ft"][key] || "",
      }));
      const grandTotal = measurements.slice(0, -1).reduce((total, measurement) => {
        return (
          total +
          (parseFloat(measurement.firstStory) || 0) +
          (parseFloat(measurement.secondStory) || 0) +
          (parseFloat(measurement.thirdStory) || 0)
        );
      }, 0);
      const porch_ceiling = Array(5).fill({
        location: "",
        length: "",
        width: "",
        squareFeet: "",
      });

      const prsa = porch_ceiling.map((item, index) => {
        return {
          location: data?.PorchCeiling[index]?.Location || "",
          length: data?.PorchCeiling[index]?.Length || "",
          width: data?.PorchCeiling[index]?.Width || "",
          squareFeet: data?.PorchCeiling[index]?.SquareFootage || "",
        };
      });
      const previewValues = {
        customer: data.CustomerDetails.Customer || "",
        city: data.CustomerDetails.City || "",
        st: data.CustomerDetails.State || "",
        job: data.CustomerDetails.JobNumber || "",
        measurements: measurements,
        roof_type: [formatTitleCase(data.RoofAndGutterDetails.RoofType)],
         ribbedRoofQuantity : (data.RoofAndGutterDetails.RoofType && data.RoofAndGutterDetails.RoofType.toLowerCase() == 'ribbed roof') 
        ? data.RoofAndGutterDetails.Quantity 
        : '',
        color: data.RoofAndGutterDetails.Color || "WHITE",
        gutter_type: data.RoofAndGutterDetails.GutterType || "FS",
        gutter_size: data.RoofAndGutterDetails.GutterSize || "4",
        total_gutter_footage: grandTotal,
        downspout:
          data.RoofAndGutterDetails.DownspoutDetails.DownspoutSize || "2x3",
        downspout_color:
          data.RoofAndGutterDetails.DownspoutDetails.DownspoutColor || "WHITE",
        downspout_footage:
          data.RoofAndGutterDetails.DownspoutDetails.DownspoutFootage || 0,
        aelbows: data.RoofAndGutterDetails.Elbows.NumberAElbows || "",
        belbows: data.RoofAndGutterDetails.Elbows.NumberBElbows || "",
        roundElbows: data.RoofAndGutterDetails.Elbows.NumberRoundElbows || "",
        offsetElbows: data.RoofAndGutterDetails.Elbows.NumberOffsetElbows || "",
        outside: data.RoofAndGutterDetails.Miters.Outside || "",
        gutter_downspout_elbows: data.RoofAndGutterDetails.TotalFootage,
        inside: data.RoofAndGutterDetails.Miters.Inside || "",
        rights: data.RoofAndGutterDetails.EndCaps.Rights || "",
        lefts: data.RoofAndGutterDetails.EndCaps.Lefts || "",
        savers: data.RoofAndGutterDetails.AdditionalParts.SpoutSavers || "",
        straps: data.RoofAndGutterDetails.AdditionalParts.RoofStraps || "",
        wedges: data.RoofAndGutterDetails.AdditionalParts.Wedges || "",
        partial_install: data.RoofAndGutterDetails.PartialInstall || "Yes",
        fascia_replacement: data.FasciaReplacement.ReplacementType || "Full",
        fascia_total_footage: grandTotal,
        fascia_size: data.FasciaReplacement.Size.CircleOption || "1x4",
        fascia_other_size: data.FasciaReplacement.Size.SizeValue,
        fascia_other: data.FasciaReplacement.wrapType.CircleOption || "Painted",
        fascia_other_value: data.FasciaReplacement.wrapType.WrapTypeValue || "",
        metal_wrapped_color:data.FasciaReplacement.wrapType.CircleOption =='Metal Wrapped' &&  data.FasciaReplacement.Color,
        fascia_type: data.FasciaReplacement.Type || "pine",
        fascia_wrap: data.FasciaReplacement.NewWrapFootage || 0,
        soffit_replacement: data.SoffitReplacement.ReplacementType || "Full",
        soffit_total_footage: grandTotal,
        soffit_other: data.SoffitReplacement.Material.CircleOption || "Wood",
        soffit_other_value: data.SoffitReplacement.Material.MaterialValue || "",
        soffit_color: data.SoffitReplacement.Color || "WHITE",
        porch_ceiling: prsa,
        files: [],
        images: [],
      };
      setLoading(false);
      setFormData(previewValues);
      router.push(`/preview/${id}?hasSheet=false&leadId=${leadDetail.leadId}`);
    } catch (error) {
      console.log(error, "nothing=>>>>>>>");
      setLoading(false);
      toast.error(
        "Your choosen image is not recognized by the system please select different image"
      );
    }
  };

  const handleSubmittoApi2 = async (selectedFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await axios.post(
        `https://scanapi.mhalt.com/upload/order-sheet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
      console.log(
        "🚀 ~ onSubmit ~ response:=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
        response
      );
      const sizes = response.data.data?.lower_analysis?.SizeGridDetails;
      const measurements = response?.data?.data.upper_analysis;
      const data = response.data?.data?.full_analysis;
      let profile=Object.keys(data?.LeafFilterProfile).filter(
        (key) => data?.LeafFilterProfile[key]
      )
      profile = profile.map(item => 
        item === 'VersaMax' ? 'Versa Max' : item
      );
      




let newMeasurements= Object.keys(measurements?.Dimensions.Columns["1st"]).map(
  (key) => ({
    label: key.replace(/([A-Z])/g, " $1").trim(),
    firstStory: [
      measurements?.Dimensions.Columns["1st"][key].Box1 || "",
      measurements?.Dimensions.Columns["1st"][key].Box2 || "",
    ],
    secondStory: [
      measurements?.Dimensions.Columns["2st"][key]?.Box1 || "",
      measurements?.Dimensions.Columns["2st"][key]?.Box2 || "",
    ],
    thirdStory: [
      measurements?.Dimensions.Columns["3rd"][key]?.Box1 || "",
      measurements?.Dimensions.Columns["3rd"][key]?.Box2 || "",
    ],
  })
)


      const totalValues = {
        firstStory: [0, 0], // total for [0] and [1] indices
        secondStory: [0, 0],
        thirdStory: [0, 0]
      };
      ['firstStory', 'secondStory', 'thirdStory'].forEach((story) => {
        [0, 1].forEach((idx) => {
          const totalForIndex = newMeasurements.reduce((total, measurement, i) => {
            if (i < newMeasurements.length - 2) {  // Exclude the "Total" measurement
              total += parseFloat(measurement[story][idx]) || 0; // Handle NaN if value is empty
            }
            return total;
          }, 0);
          totalValues[story][idx] = totalForIndex; // Store total for each index (0 and 1)
        });
      });
        // Update the "Total" section with the new calculated totals for all stories and indices
        newMeasurements[newMeasurements.length - 2].firstStory[0] = totalValues.firstStory[0].toString() || 0;
        newMeasurements[newMeasurements.length - 2].secondStory[0] = totalValues.secondStory[0].toString() || 0;
        newMeasurements[newMeasurements.length - 2].thirdStory[0] = totalValues.thirdStory[0].toString() || 0;
      
        // Store the totals for the second index (index 1) for each story as well
        newMeasurements[newMeasurements.length - 2].firstStory[1] = totalValues.firstStory[1].toString() || 0;
        newMeasurements[newMeasurements.length - 2].secondStory[1] = totalValues.secondStory[1].toString() || 0;
        newMeasurements[newMeasurements.length - 2].thirdStory[1] = totalValues.thirdStory[1].toString() || 0;
        const grandTotal = Object.values(totalValues).reduce((storySum, storyTotals) => {
          return storySum + storyTotals.reduce((indexSum, indexTotal) => indexSum + indexTotal, 0);
        }, 0);



      const orderValues = {
        customer: data?.CustomerDetails.Customer,
        city: data?.CustomerDetails.City,
        state: data?.CustomerDetails.State,
        job: data?.CustomerDetails.JobNumber,
        gutterProfile:
          Object.keys(data?.GutterProfile).filter(
            (key) => data?.GutterProfile[key]
          )[0] || "K-Style",
        leafFilterProfile:profile,
        versa_max_quantity: data?.GutterDetails?.VersaMaxQuantity?.Value,
        measurements:newMeasurements,
        color: data?.LeafFilterColor.ColorOptions,
        leafFilterColor: data?.LeafFilterColor.ModularAndVersaColor,
        existingGutterColor: data?.LeafFilterColor.ExistingGutterColor,
        gutterOpeningWidth:data?.GutterDetails?.GutterOpeningWidth?.CircleOption,
        gutterOpeningWidthValue: data?.GutterDetails?.GutterOpeningWidth?.CustomWidth,
        totalFootage: grandTotal,
        partial: data?.GutterDetails.TotalFootage.Partial,
        csr: data?.GutterDetails.CleanSealRainforce.CircleOption === "Clean",
        csr_value: data?.GutterDetails.CleanSealRainforce.Footage || "",
        insideCorners: data?.GutterDetails.InsideCorners,
        outsideCorners: data?.GutterDetails.OutsideCorners,
        existingProtection: data?.GutterDetails.ExistingProtection.Required,
        existingProtectionType:
          data?.GutterDetails.ExistingProtection.Type || "Screen",
        mountingType: data?.GutterDetails.FastenerTypes.CircleOption,
        mountMethod: "",
        mountingLength: data?.GutterDetails.FastenerTypes.Footage || 0,
        roof: data?.GutterDetails.SpringClipsRequired.Required,
        roofColor: data?.GutterDetails.SpringClipsRequired.Color,
        roofType: data?.GutterDetails.RoofTypes.CircleOption,
        roofTypeValue: data?.GutterDetails.RoofTypes.CircleOption=='Other' ?  data?.GutterDetails.RoofTypes.RoofTypeValue : '',
        extensionsNeeded: data?.AdditionalDetails.ExtensionsNeeded
          ? "Yes"
          : "No",
        extensionFootage: data?.AdditionalDetails.Footage,
        groundSpoutNeeded: data?.AdditionalDetails.GroundspoulNeeded
          ? "Yes"
          : "No",
        units: data?.AdditionalDetails.Units,
        sizes: Object.entries(sizes).map(([key, values]) => ({
          label: key.replace(")", ""),
          values: [
            values["2x3"] || null,
            values["3x4"] || null,
            values["Round 3"] || null,
            values["Round 4"] || null,
          ],
        })),
        AElbows: data?.AdditionalDetails.Elbows.NumberAElbows,
        BElbows: data?.AdditionalDetails.Elbows.NumberBElbows,
        spoutSaverQuantity: data?.AdditionalDetails.SpoutsaverQuantity || "",
        ladders: data?.AdditionalDetails.Ladders.CircleOption,
        laddersValue: data?.AdditionalDetails.Ladders.LadderValue,
        walkboards: data?.AdditionalDetails.WalkBoards ? "Yes" : "No",
        obstruction: data?.AdditionalDetails.Obstructions,
        note: data?.Notes,
        files: [],
        hasSheet: false,
      };
      setLoading(false);
      setOrderFormData(orderValues);
      router.push(
        `/scan-preview/${id}?hasSheet=false&leadId=${leadDetail.leadId}`
      );
    } catch (error) {
      console.log(error)
      setLoading(false);
      toast.error(
        "Your choosen image is not recognized by the system please select different image"
      );
    }
  };




  function blobToFile(blob, fileName) {
    return new File([blob], fileName, {
      type: blob.type,
      lastModified: new Date().getTime(),
    });
  }






const cropImageNow = () => {
  setCropLoading(true)
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  // Adjust canvas size for high resolution
  const pixelRatio = window.devicePixelRatio;
  const qualityMultiplier = 5;
  let effectiveRatio = pixelRatio * qualityMultiplier;

  canvas.width = crop.width * effectiveRatio;
  canvas.height = crop.height * effectiveRatio;

  const ctx = canvas.getContext("2d");

  // Set transformation for high-quality scaling
  ctx.setTransform(effectiveRatio, 0, 0, effectiveRatio, 0, 0);
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";

  // Draw the cropped image
  ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
  );

  // Export to PNG
  let base64Image = canvas.toDataURL("image/png");
  let imageSizeKB = base64Image.length / 1024;

  // Downscale if size exceeds 4MB
  while (imageSizeKB > 4000 && effectiveRatio > 1) {
      effectiveRatio *= 0.9; // Reduce the ratio by 10%
      canvas.width = crop.width * effectiveRatio;
      canvas.height = crop.height * effectiveRatio;

      ctx.setTransform(effectiveRatio, 0, 0, effectiveRatio, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
      ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
      );

      base64Image = canvas.toDataURL("image/png");
      imageSizeKB = base64Image.length / 1024;
  }

  if (imageSizeKB <= 4000) {
      setOutput(base64Image);
  setCropLoading(false)
      setIsUsed(true);
  } else {
  setCropLoading(false)
      console.error("Failed to generate an image under 4MB.");
  }
};


  function base64ToFile(base64String, filename, mimeType) {
    // Decode the base64 string
    const byteString = atob(base64String.split(',')[1]);
  
    // Create an array of 8-bit unsigned integers
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
  
    // Create a Blob from the byteArray
    const file = new File([byteArray], 'myImage', { type: 'image/png' });
  
    return file;
  }
  const selectImage = (file) => {
    if(file){
      setOutput(URL.createObjectURL(file));
      setSrc(URL.createObjectURL(file));
    }
  };

  function saveBase64AsJPG(base64String, fileName) {
    try {
        // Remove Base64 prefix if present
        const base64Content = base64String.includes(",")
            ? base64String.split(",")[1]
            : base64String;

        // Decode Base64 string to binary data
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/jpeg" });

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        // Append to the document to ensure it works in all browsers
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element
        document.body.removeChild(link);

        // Clean up the URL object
        URL.revokeObjectURL(link.href);

        console.log("Image saved successfully!");
    } catch (error) {
        console.error("Error saving image:", error);
    }
}


  return (
    <div className="container-dailog">
      <Modal isOpen={visible} onClose={() => toggleDialog()} loading={loading}>
        <div className="modal-title-container">
          <div className="modal-title"></div>
          <Close onClick={() => toggleDialog()} />
        </div>
        <div
          className={`k-py-${file ? 2 : 12} k-px-2 k-less-padding k-scrollable`}
        >
          <div className="k-text-center">
            <h1 className="k-font-semibold">
              {scanType != "Upload Image"
                ? "Choose Entry Method"
                : file
                ? "Crop Image"
                : "Choose Image"}
            </h1>
            <h4 className="k-font-normal k-mt-4">
              {scanType != "Upload Image"
                ? "Select an option to proceed with your document submission"
                : file
                ? "Please ensure that the image you upload contains only the paper and no other objects or backgrounds"
                : `Please upload the ${scanSheetType} Image`}
            </h4>
          </div>
          {!file && (
            <div
              className={`k-display-flex k-md-flex-column k-md-gap-less k-justify-content-center k-gap-20 k-mt-10 k-px-8`}
            >
              {scanType != "Upload Image" &&
                currentView == "arr1" &&
                arr1.map((item) => (
                  <EntryCard handleClick={arr1Click} item={item} />
                ))}

              {scanType != "Upload Image" &&
                currentView == "arr2" &&
                arr2.map((item) => (
                  <EntryCard2 handleClick={arr2Click} item={item} />
                ))}
              {/* {scanType != "Upload Image" &&
              currentView == "arr3" &&
              arr3.map((item) => (
                <EntryCard3 handleClick={arr3Click} item={item} />
              ))} */}
            </div>
          )}
          {scanType == "Upload Image" && (
            <>
              <div
                className={`k-display-flex k-gap-5 ${styles.uploaderContainer}`}
                style={{ width: "100%" }}
              >
                <Upload
                  accept="image/*"
                  batch={false}
                  multiple={false}
                  defaultFiles={[]}
                  withCredentials={false}
                  saveUrl={
                    "https://demos.telerik.com/kendo-ui/service-v4/upload/save"
                  }
                  removeUrl={
                    "https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
                  }
                  onAdd={handleAdd} // Handle file addition
                  onRemove={handleRemove} // Handle file removal
                />
              </div>
              {file && (
                <div
                  className={`k-display-flex k-md-flex-column k-md-gap-less k-justify-content-center k-gap-20 k-mt-2`}
                >
                  {/* {file &&    <div className="Crop-Controls">
                  <input type="file" accept="image/*" onChange={(e)=>onSelectFile(e.target.files[0])} />
                  <div>
                    <label htmlFor="scale-input">Scale: </label>
                    <input
                      id="scale-input"
                      type="number"
                      step="0.1"
                      value={scale}
                      disabled={!imgSrc}
                      onChange={(e) => setScale(Number(e.target.value))}
                    />
                  </div>
                  <div>
                    <label htmlFor="rotate-input">Rotate: </label>
                    <input
                      id="rotate-input"
                      type="number"
                      value={rotate}
                      disabled={!imgSrc}
                      onChange={(e) =>
                        setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                      }
                    />
                  </div>
                  <div>
                    <button onClick={handleToggleAspectClick}>
                      Toggle aspect {aspect ? 'off' : 'on'}
                    </button>
                  </div>
                </div>
                } */}
                  <div>
                    <div className="k-display-flex k-justify-content-between">
                      <h2 className="k-m-0 k-pb-2">Image</h2>
                      <div className="">
                        <Button
                          className="k-button-green k-mb-2"
                          size="large"
                          disabled={cropLoading}
                          style={{ width: "100px" }}
                          primary={true}
                          onClick={cropImageNow}
                        >
                          {cropLoading ? 'Croping' : 'Crop'}
                        </Button>
                      </div>
                    </div>
                    {/* {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={undefined}
          // minWidth={400}
          minHeight={100}
          // circularCrop
        >
          <img
          height={500}
          className="myCanvusandImage"
          ref={imgRef}
           crossOrigin="anonymous"
            alt="Crop me"
            src={imgSrc || ''}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )} */}
                  {src &&  <ReactCrop
                      src={src}
                      onImageLoaded={setImage}
                      crop={crop}
                      onChange={setCrop}
                    />}
                  </div>

                  <div>
                    <h2 className="k-m-0 k-pb-2 k-mb-3">Result</h2>
                    {/* {!!completedCrop && (
                  <>
                    <div>
                      <canvas
                      className="myCanvusandImage"
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop.width,
                          // height: completedCrop.height,
                        }}
                      />
                    </div>
                    <div>
  
                      <a
                        href="#hidden"
                        ref={hiddenAnchorRef}
                        download
                        style={{
                          position: 'absolute',
                          top: '-200vh',
                          visibility: 'hidden',
                        }}
                      >
                        Hidden download
                      </a>
                    </div>
                  </>
                )} */}
                    <div>
                      {output && (
                        <img
                        className="myCanvusandImage2"
                          height={500}
                          src={output}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {scanType == "Upload Image" && (
          <div className="k-display-flex k-justify-content-end k-my-4">
            <Button
              disabled={loading}
              style={{ width: "100px" }}
              primary={true}
              className="k-button-red2 k-mr-2"
              size="large"
              onClick={() => {
                setScanType(null);
                setIsUsed(false);
                setFile(null);
                setImgSrc(null);
              }}
            >
              Back
            </Button>
            <Button
              style={{ width: "100px" }}
              primary={true}
              onClick={() => {
                if (isUsed) {
                  const myfile = base64ToFile(output);
                  console.log(myfile,"dsadhsajdh")
                  if (!myfile) {
                    return toast.error("Please Choose The Image");
                  } else {
                    if (scanSheetType == "Gutter Sheet") {
                      handleSubmittoApi(myfile);
                    } else {
                      handleSubmittoApi2(myfile);
                    }
                  }
                } else {
                  if (!file) {
                    return toast.error("Please Choose The Image");
                  } else {
                    if (scanSheetType == "Gutter Sheet") {
                      handleSubmittoApi(file);
                    } else {
                      handleSubmittoApi2(file);
                    }
                  }
                }
              }}
              disabled={loading}
              className="k-button-green k-mr-2"
              size="large"
            >
              {loading ? (
                <>
                  <Loader
                    size="small"
                    type="converging-spinner"
                    style={{ color: "white" }}
                  />{" "}
                  Scaning
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        )}
      </Modal>

      {scanType == "Upload Image" && loading && (
        <div class="loader-overlay-calander">
          {/* <div class="loader-calander"></div> */}
          {/* <div class="content-behind-calander">
            <h3>Loading...</h3>
          </div> */}
          <div
            className="k-my-10 k-rounded-lg k-p-4 "
            style={{ backgroundColor: "#F3F3F3" }}
          >
            {/* <img src={url} alt="Screenshot"/> */}
            <div id="dup-scanning-files">
              <div class="scanning-wrapper">
                <div class="top-corners"></div>
                <div class="bottom-corners"></div>
                <div class="file-types">
                  <img
                    style={{ objectFit: "contain", height: "500px" }}
                    src={isUsed ? output : URL.createObjectURL(file)}
                    alt="Screenshot"
                  />
                </div>
                <div class="scanner"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DialogSelect;

import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

//

export default function MainLayout({ children, footer = true }) {
  return (
    <div>
      <Header />
      <div className="k-px-10 k-sm-px-2 container">
        {children}
        {footer && <Footer />}
      </div>
    </div>
  );
}

import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";

const HomeToolBar = () => {
  function formatDate() {
    const options = { weekday: 'long' };
    const today = new Date();
    
    const dayOfWeek = today.toLocaleDateString('en-US', options);
    const month = today.getMonth() + 1; // Months are 0-based
    const day = today.getDate();
    const year = today.getFullYear();
    
    return `${dayOfWeek} ${month}/${day}/${year}`;
  }

  return (
    <div>
      <h3>LEADS FOR:</h3>
      <h1>{}</h1>
      <div className="k-my-6">
        <Button
          primary={true}
          className="k-button-blue k-button-sm-responsive"
          size="large"
        >
          Refresh Leads
        </Button>
      </div>
      <div className="k-my-6">
        <Button
          primary={true}
          className="k-button-blue k-button-sm-responsive"
          size="large"
        >
          Create Self Gen
        </Button>
      </div>
      <div className="k-my-6">
        <Button
          primary={true}
          className="k-button-blue k-button-sm-responsive"
          size="large"
        >
          Create Rehash
        </Button>
      </div>
      <div className="k-display-flex k-align-items-center k-flex-direction-sm">
        <div className="k-display-flex k-align-items-center">
        <div>
          <h4 className="k-font-medium">JOB NUMBER:</h4>
        </div>
        <div className="k-mx-2">
          <Input />
        </div>
        </div>
        <div className=" k-button-sm-responsive">
          <Button
            primary={true}
            className="k-button-blue k-button-sm-responsive"
            size="large"
          >
            Schedule Unscheduled Job
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeToolBar;

import React, { useState } from "react";
import Input from "../../components/preview-input/input";
import DownspoutSelection from "./downspout-selection";
import GutterSizeSelection from "./gutter-size-selection";
import GutterTypeSelection from "./gutter-type-selection";
import PartialInstallSelection from "./partial-install-selection";
import usePreviewStore from "../../store/preview-store";
import DownspoutDetails from "./downspout-details";
import TotalFootageDetails from "./total-footage-details";
import { useLocation } from "react-router-dom";

const MeasurementsRight = () => {
  const { previewData, setFormData } = usePreviewStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };

  const roofOptions = [
    "Asphalt Shingle",
    "Metal",
    "Tile",
    "Slate",
    "Flat Roof",
    "Wood Shake",
    "Ribbed Roof",
  ];


  

const handleCheckboxChange = (e) => {
  const { value, checked } = e.target;

  let updatedRoofTypes = Array.isArray(previewData.roof_type) ? [...previewData.roof_type] : [];
  
  if (checked) {
    updatedRoofTypes.push(value);
  } else {
    updatedRoofTypes = updatedRoofTypes.filter((type) => type !== value);
  }
  console.log(updatedRoofTypes, "Updated roof types"); // Debugging log
  setFormData({ ...previewData, roof_type: updatedRoofTypes });
};
  return (
    <div className="k-pl-6 k-pb-4 k-pt-2 k-sm-pl-0">
        <div className="k-display-flex k-flex-direction-sm k-sm-gap-2">
      <h4 className="k-m-0 k-font-light k-pt-3" style={{width:"180px"}}>Roof Types :</h4>
      <div className="k-pos-relative k-top-1 k-display-flex k-gap-5" style={{flexWrap:"wrap"}}>
        {roofOptions.map((option) => (
          <label key={option} className="k-display-flex k-align-center k-gap-2 k-pt-2">
            <input
              type="checkbox"
              value={option}
              checked={previewData.roof_type.includes(option)}
              onChange={handleCheckboxChange}
              disabled={isDisabled}
            />
            {option}
          </label>
        ))}
            {previewData.roof_type.includes("Ribbed Roof") && (
     <div className="k-display-flex k-gap-2 k-manage-width">
     <h4 className="k-m-0 k-font-light k-pt-2">Ribbed Roof Quantity:</h4>
     <div className="k-pos-relative k-top-1">
       <Input
       type="number"
         value={previewData.ribbedRoofQuantity}
         onChange={handleInputChange("ribbedRoofQuantity")}
       />
     </div>
   </div>
      )}
      </div>

  
    </div>
      <GutterTypeSelection />
      <GutterSizeSelection />

      <div className="k-display-flex k-flex-wrap k-gap-2 k-mt-2">
        <h4 className="k-m-0 k-font-light k-pt-2">Total Gutter Footage:</h4>
        <div className="k-pos-relative k-top-1">
          <Input
            disable
            value={previewData.total_gutter_footage}
            onChange={handleInputChange("total_gutter_footage")}
          />
        </div>
      </div>
      <DownspoutSelection />
      <DownspoutDetails />

      <div className="k-d-grid k-px-4 k-border-solid k-border k-border-black k-mt-3">
        <div className="k-display-flex k-flex-wrap k-gap-4 k-sm-gap-2">
          <h3 className="k-m-0 k-py-3">
            Total Footage (gutter + downspout + elbows):
          </h3>
          <div className="k-pos-relative k-mt-3">
            <Input
            disable
              value={Number(previewData?.total_gutter_footage)+Number(previewData?.aelbows)+Number(previewData?.belbows)+Number(previewData?.roundElbows)+Number(previewData?.offsetElbows)+Number(previewData.downspout_footage)}
              onChange={handleInputChange("gutter_downspout_elbows")}
            />
          </div>
        </div>
      </div>

      <TotalFootageDetails />

      <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1 k-mt-2 k-pr-4">
        <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
          <h4 className="k-m-0 k-font-light k-pt-2"># of Wedges:</h4>
          <div className="k-pos-relative k-top-1 k-right-3 k-sm-right-0">
            <Input
            type="number"
              value={previewData.wedges}
              onChange={handleInputChange("wedges")}
            />
          </div>
        </div>
        <PartialInstallSelection />
      </div>
    </div>
  );
};

export default MeasurementsRight;
